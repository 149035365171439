import { useEffect, useState } from "react";
import { DemandService } from "../../../services/demand";
import { DemandModel } from "../../../models";
import { useParams } from "react-router-dom";
import CTab from "../../../components/CTab/CTab";
import { TabColumn } from "../../../components/CTab/model/TabColumn";
import { TabProps } from "../../../components/CTab/model/TabProps";
import CTabItem from "../../../components/CTabItem/CTabItem";
import CButton from "../../../components/CButton/CButton";
import { ButtonColors } from "../../../shared/constant/color/ButtonColors";
import { HttpStatusCodes } from "../../../shared/enum/HttpStatusCodes";
import { isNullOrEmpty } from "../../../base/helpers";
import { MdOutlineAssignmentInd } from "react-icons/md";
import { DemandDetailService } from "../../../services/demand-detail";
import { useSelector } from "react-redux";
import { FileService } from "../../../services/file";

export default function EditDemand() {
  const { id } = useParams();

  const [demandId, setDemandId] = useState<any>();
  const [demand, setDemand] = useState<any>();
  const [demandData, setData] = useState<any>();
  const [mainType, setMainType] = useState<any>();
  const [demandDetails, setDemandDetails] = useState<any>();
  const [finallyData, setFinallyData] = useState<any>();
  const [assingButtonVisible, setVisibleAssinged] = useState<boolean>();
  const { updateDemandAssing, getDemandAllDetailById } = DemandService();
  const { downloadFile } = FileService();
  const tmpUser = useSelector((state: any) => state.user.userInfo);
  const userInfo = tmpUser[0];

  useEffect(() => {
    
    setDemandId(id);

    const fetchData = async () => {
      try {
        const response = await getDemandAllDetailById(id);

        if (
          response.status === HttpStatusCodes.Success &&
          !isNullOrEmpty(response.data)
        ) {
          const demands: any = response.data["demands"];
          const finallyData: any = response.data["finallyData"];
          const demandsDetails: any = response.data["demand-details"];
          const demandsType = response.data["demand-type"];

          setDemand(demands);

          setDemandDetails(demandsDetails);
          setFinallyData(finallyData[0].fields);

          let tmpMainType = demandsType.filter(
            (x) =>
              x.main_special_name === undefined || x.main_special_name === null
          );
          tmpMainType = tmpMainType[0];
          setMainType(tmpMainType);

          // && tmpData.["assigned_personelid"] === 1
          if (demands && demands.assigned_personelid === userInfo?.id) {
            setVisibleAssinged(false);
          } else {
            setVisibleAssinged(true);
          }

          setData(demands);
        } else {
          throw new Error(
            "Talep Bilgisi Alınamadı! Hata Kodu: " + response?.status
          );
        }
      } catch (e: any) {
        console.error("Error fetching data:", e);
      }
    };
    fetchData();
  }, []);

  const tabColumns: TabColumn[] = [
    { label: "Talep Bilgileri", text: "Tab text1" },
    { label: "Talep Dosyaları", text: "Tab text2" },
    { label: "Tarihçe", text: "Tab text3" },
  ];

  const tabOptions = {
    title: "Talep Detayları",
  };

  const tabProps: TabProps = {
    columns: tabColumns,
    options: tabOptions,
  };

  const assignDemand = async (e: any) => {
    try {
      const response = await updateDemandAssing({ id: id });

      if (response) {
        setVisibleAssinged(false);
      }
    } catch (e: any) {
      console.error("Error fetching data:", JSON.stringify(e));
    }
  };

  const downloandFile = async (demand_detail_sub_id: number, dosyaAdi: string) => {
    try {
      const tmpData = { 
        demand_id: demandId,
        demand_detail_sub_id: demand_detail_sub_id
      };
      
      await downloadFile({"demandDetail": tmpData })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'talep_' + demand.id + '_' + dosyaAdi + '.pdf'); // İndirilecek dosya adı
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error('Dosya indirme hatası:', error));

    } catch (e: any) {
      console.error("Error fetching data:", JSON.stringify(e));
    }
  }
  

  const assingButtonOptions = {
    color: ButtonColors.Primary,
    text: "Talebi Bana Ata",
    onClickFunc: assignDemand,
    icon: MdOutlineAssignmentInd,
  };

  // const buttonOptions = {
  //   color: ButtonColors.Primary,
  //   text: 'Talebi Bana Ata',
  //   onClickFunc: assignDemand,
  //   icon: MdOutlineAssignmentInd,
  // };

  const formatDate = (dateString: any) => {
    if (!isNullOrEmpty(dateString)) {
      const date = new Date(dateString);
      if (!isNaN(date.getTime())) {
        return date.toISOString().split("T")[0];
      }
    }
    return "";
  };

  const formatDateTime = (dateString: any) => {
    if (!isNullOrEmpty(dateString)) {
      const date = new Date(dateString);
      if (!isNaN(date.getTime())) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        return `${year}-${month}-${day}T${hours}:${minutes}`;
      }
    }
    return "";
  };

  return (
    <>
      <CTab props={tabProps}>
        <CTabItem>
          <div className="card-body py-3">
            <div className="row mb-6">
              <label
                htmlFor="name"
                className="col-lg-3 col-form-label fw-bold fs-6"
              >
                Talep Tipi:
              </label>
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-6 fv-row">
                    <input
                      type="text"
                      id="name"
                      disabled
                      value={mainType?.title ?? ""}
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    />
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label
                htmlFor="name"
                className="col-lg-3 col-form-label fw-bold fs-6"
              >
                Talep Oluşturulma Tarihi:
              </label>
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-6 fv-row">
                    <input
                      type="datetime-local"
                      id="created_date"
                      disabled
                      value={formatDateTime(demandData?.created_date) ?? ""}
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    />
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label
                htmlFor="name"
                className="col-lg-3 col-form-label fw-bold fs-6"
              >
                Atanan Personel:
              </label>
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-6 fv-row">
                    <input
                      type="text"
                      id="name"
                      disabled
                      value={demandData?.assigned_personelid ?? "Atanmadı"}
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    />
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label
                htmlFor="name"
                className="col-lg-3 col-form-label fw-bold fs-6"
              >
                Talep Açıklaması:
              </label>
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-6 fv-row">
                    <input
                      type="text"
                      id="name"
                      disabled
                      value={demandData?.description ?? ""}
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    />
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label
                htmlFor="name"
                className="col-lg-3 col-form-label fw-bold fs-6"
              >
                Müşteri Notu:
              </label>
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-6 fv-row">
                    <input
                      type="text"
                      id="name"
                      disabled
                      value={demandData?.note ?? ""}
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    />
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row mb-6">
              <label
                htmlFor="name"
                className="col-lg-3 col-form-label fw-bold fs-3"
              >
                Alanlar
              </label>
            </div>
            <hr />
            {finallyData &&
              finallyData.length > 0 &&
              finallyData.map((item) => {
                return (
                  <>
                    <div className="row mb-6">
                      <label
                        htmlFor="name"
                        className="col-lg-3 col-form-label fw-bold fs-6"
                      >
                        {item?.title}
                      </label>
                      {item.data_type === "file" ?
                        (
                          <div className="col-lg-9">
                            <div className="row">
                              <div className="col-lg-6 fv-row">
                                <button
                                  id="name"
                                  onClick={() => downloandFile(item.id, item.title)}
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                >
                                  Dosya İndir
                                </button>
                                <br />
                              </div>
                            </div>
                          </div>
                        ) :
                        <div className="col-lg-9">
                          <div className="row">
                            <div className="col-lg-6 fv-row">
                              <input
                                type="text"
                                id="name"
                                disabled
                                value={item?.value ?? ""}
                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              />
                              <br />
                            </div>
                          </div>
                        </div>}
                    </div>
                  </>);
              })}
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            {assingButtonVisible && <CButton options={assingButtonOptions} />}
          </div>
        </CTabItem>
        <CTabItem>
          <input
            type="text"
            disabled
            placeholder="Second Tab Input"
            value={1}
          />
          <button>Submit</button>
        </CTabItem>
        <CTabItem>
          <div>Third Tab Content</div>
        </CTabItem>
      </CTab>
    </>
  );
}
