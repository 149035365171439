import { Logout } from "../../app/modules/auth";
import { getAuth } from "../../app/modules/auth/core/AuthHelpers";
import { isNullOrEmpty } from "../../base/helpers";
import { HttpStatusCodes } from "../../shared/enum/HttpStatusCodes";

const useProxyManager = () => {

    //const apiURL = process.env.REACT_APP_MAIN_BACKEND_API_URL ?? "https://api.assistouch.com/api/v1/";
    //const apiURL = "http://192.168.56.1:4000/api/v1/";
    const apiURL = "https://api.assistouch.com/api/v1/";//
    //https://api.assistouch.com/api/v1/"
    //http://localhost:4000/api/v1/

    //const navigation = useNavigate();

    const handleResponse = async (response: any) => {

        if (isNullOrEmpty(response) || isNullOrEmpty(response.status)) {
            document.cookie = "refreshToken" + "=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/; Secure";
            //removeAuth();
            //navigation('/auth');
            <Logout />
            // <Link to="/auth" />
        } else {
            if (response.status === HttpStatusCodes.UnAuthorization) {
                // Çerezi geçmiş bir tarihle ayarlayarak siler
                document.cookie = "refreshToken" + "=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/; Secure";
                //removeAuth();
                //navigation('/auth');
                <Logout />
                // <Link to="/auth" />
            } else if (response.status === HttpStatusCodes.Success || response.status === HttpStatusCodes.Created || response.status === HttpStatusCodes.NotFound || response.status === HttpStatusCodes.BadRequest) {
                return response.json();
            } else {
                if (response.status === HttpStatusCodes.ServerError) {
                    return response.json();
                }
            }
        }

    };


    /*const handleResponse = async (response: any) => {
        //TODO: kontrol düzeltilecek, backend düzeltilince burayıda düzelticem
        if (!response.status && response.status === "OK") {
            const error = await response.json();
            throw new Error(error.message || 'Something went wrong');
        }
        return response.json();
    };*/

    //TODO: spinner entegrasyonu
    /*const httpGet = async (endpoint:string) => {

        try {
            const response = await fetch(apiURL + endpoint, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            //TODO: response handler - middleware entegrasyonu
            return await handleResponse(response);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };*/

    const httpGet = async (endpoint: string) => {
        try {
            let auth = getAuth();
            //TODO: kontrol
            const token = auth?.access_token ?? "";
            const refreshToken = getCookie('refreshToken');

            const url = apiURL + endpoint;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'Refresh-Token': 'Bearer ' + refreshToken
                }
            });
            //TODO: response handler - middleware entegrasyonu
            return await handleResponse(response);
        } catch (err) {
            console.error(err);
        }
    };

    const httpGet2 = async (endpoint: string) => {
        try {
            let auth = getAuth();
            //TODO: kontrol
            const token = auth?.access_token ?? "";
            const refreshToken = getCookie('refreshToken');

            const url = apiURL + endpoint;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'Refresh-Token': 'Bearer ' + refreshToken
                }
            });
            //TODO: response handler - middleware entegrasyonu
            //return await handleResponse(response);
            console.log('response1: ', response);
            return await response;
        } catch (err) {
            console.error(err);
        }
    };

    const httpPost = async (endpoint: string, data: any) => {
        try {
            let auth = getAuth();
            const token = auth?.access_token ?? "";
            const refreshToken = getCookie('refreshToken');

            const url = apiURL + endpoint;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'Refresh-Token': 'Bearer ' + refreshToken
                },
                body: JSON.stringify(data)
            });
            //TODO: response handler - middleware entegrasyonu
            return await handleResponse(response);
        } catch (err) {
            console.error(err);
        }
    };

    const httpPostFile = async (endpoint: string, data: any) => {
        try {
            let auth = getAuth();
            const token = auth?.access_token ?? "";
            const refreshToken = getCookie('refreshToken');

            const url = apiURL + endpoint;

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'Refresh-Token': 'Bearer ' + refreshToken
                },
                body: JSON.stringify(data)
            })

            return response;

        } catch (err) {
            console.error(err);
        }
    };

    const httpPostFormData = async (endpoint: string, data: any) => {
        try {
            console.log('datA: ', data);
            let auth = getAuth();
            const token = auth?.access_token ?? "";
            const refreshToken = getCookie('refreshToken');
            //'Content-Type': 'multipart/form-data',

            const url = apiURL + endpoint;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Refresh-Token': 'Bearer ' + refreshToken
                },
                body: data
            });
            //TODO: response handler - middleware entegrasyonu
            return await handleResponse(response);
        } catch (err) {
            console.error(err);
        }
    };

    const httpPostLogin = async (endpoint: string, data: any) => {
        try {
            const url = apiURL + endpoint;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });
            //TODO: response handler - middleware entegrasyonu
            return await handleResponse(response);
        } catch (err) {
            console.error(err);
        }
    };

    /*
    * Asekron Çalışır
    */
    /* const asyncHttpGet = async (endpoint:string) => {
         const response = fetch(apiURL + endpoint, {
             method: "GET",
         });
         return response;
     };*/

    //private functions

    function getCookie(name: any) {
        let cookieArr = document.cookie.split(";");
        for (let i = 0; i < cookieArr.length; i++) {
            let cookiePair = cookieArr[i].split("=");
            if (name === cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1]);
            }
        }
        return null;
    }

    return {
        httpGet,
        httpPost,
        httpPostLogin,
        httpPostFormData,
        httpPostFile
    };

};

export default useProxyManager;