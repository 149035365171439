import CButton from "../../../components/CButton/CButton";
import CSwitch from "../../../components/CSwitch/CSwitch";
import CTab from "../../../components/CTab/CTab";
import { TabColumn } from "../../../components/CTab/model/TabColumn";
import { TabProps } from "../../../components/CTab/model/TabProps";
import { ButtonColors } from "../../../shared/constant/color/ButtonColors";


export function ComponentTestPage(){

    const buttonOptions = {
        color: ButtonColors.DarkSuccess,
        text: 'Test',
        redirectUrl: '/user-list'
    };

    const switchOptions = {
        text: 'Test',
        checked: true,
        disabled: true,
        height: 40,
        width: 60
    };

    const tabColumns: TabColumn[] = [
        { label: 'Tab1', text: 'Tab text1'},
        { label: 'Tab2', text: 'Tab text2'},
        { label: 'Tab3', text: 'Tab text3'}
    ];

    const tabOptions = {
        title: 'Tab Title',
    };

    const tabProps: TabProps = {
        columns: tabColumns,
        options: tabOptions
    };

    return (
        <>
        <h1>Component Test Sayfası</h1>
        <CButton options={buttonOptions}/>

        <CSwitch options={switchOptions} />

        <CTab props={tabProps} />

        </>
    )
    
}